.card-default {
  color: '#333333';
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  img {margin-right: 1rem;}
}