.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.6) !important;
  padding-top: 65px;
}

.modal {
  padding: 0;
  margin: 0;
  // margin-top: 65px;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  background-color: rgba(255, 255, 255, 1);
  

  .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #eee;
    position: fixed;
    width: calc(100% - 2rem);
    background-color: white;

    .title {
      font-weight: 700;
    }
  }

  .modal-body {
    padding: 0 1rem;
    margin-top: 65px;
  }
}