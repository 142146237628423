.intro-section {
  background-color: white;
  padding: 1rem;
}

.form-group {
  // * {
  //   display: block;
  // }
  padding-top: 1rem;

  button {
    cursor: pointer;
  }
}