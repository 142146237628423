// scheduling modal content
.appt-row {
  .date {
    font-weight: 700;
    padding-top: 0.5rem;
  }
  .appt-grid {
    padding: 0.5rem 0 0.75rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    * {
      margin-right: 1rem;
    }
  }
}