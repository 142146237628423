.button-card {
  padding: 1rem 0;

  .button-row {
    display: flex;
    flex-direction: row;
    padding-top: 1rem;

    button {
      width: 100%;
      margin-right: 1rem;
    }
  }
}