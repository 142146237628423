.header {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  height: 70px;

  .arrow {
    transform: rotate(-90deg);
    cursor: pointer;
    filter: invert(30%) sepia(87%) saturate(3182%) hue-rotate(211deg) brightness(96%) contrast(108%);
  }

  .inactive {
    opacity: .5;
    cursor: auto;
  }

  .logo {
    max-width: 150px;
  }

  .lang-selector {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      margin-left: 5px;
      margin-top: 2px;
    }
  }
}